/* eslint-disable */
export default () => {
	var $popup = $('.js-ajax-popup');
	var $clientName = $('.js-client_name');
	var $slidesCheckboxes = $('[class^="js-checkbox-"]');
	var $submitBtn = $('.js-appie');
	var $url = $('.js-url');
	const originalLink =
		'/slides?&a=t&b=t&c=t&d=t&e=t&f=t&g=t&h=t&i=t&j=t&k=t&l=t&m=t&n=t&o=t&p=t&q=t';

	/********** Init **********/
	(function init() {
		$url.attr('value', originalLink);
		$submitBtn.attr('disabled', true);
		toggleSubmitBtn();

		/********** Events **********/
		/* Toggle Submit Btn */
		$clientName.on('input', toggleSubmitBtn);

		/* Toggle Slide */
		$slidesCheckboxes.on('click', toggleSlides);

		/* Submit */
		$submitBtn.on('click', submit);

		/* Close Popup */
		$popup.find('.js-ajax-popup__close').on('click', closePopup);
	})();

	/********** Functions **********/
	function toggleSubmitBtn() {
		if ($clientName.val()) {
			$submitBtn.attr('disabled', false);
		} else {
			$submitBtn.attr('disabled', true);
		}
	}

	function toggleSlides(e) {
		if (e.target.value == 'toggle') {
			let newUrl = e.target.checked ? originalLink : '';
			$url.attr('value', newUrl);

			// toggle all slides
			$slidesCheckboxes.prop('checked', e.target.checked);
		} else {
			var letter = `&${e.target.value}=t`;
			var currentUrl = $url.val();

			if (currentUrl.includes(letter)) {
				currentUrl = currentUrl.replace(letter, '');

				// uncheck ToggleAll-btn
				$('#toggle-all').prop('checked', false);
			} else {
				currentUrl += letter;

				// check ToggleAll-btn if all slides are checked
				if (currentUrl.length == originalLink.length) {
					$('#toggle-all').prop('checked', true);
				}
			}

			$url.attr('value', currentUrl);
		}
	}

	function closePopup() {
		$popup
			.find('.js-ajax-popup__msg')
			.text('')
			.parent()
			.hide();
	}

	function submit() {
		$.post('/create-client/', {
			client_name: $clientName.val(),
			client_url: $url.val(),
			rep_id: $('#rep_id').val()
		}).done(function(data) {
			// show overlay
			$popup
				.find('.js-ajax-popup__msg')
				.text(data.error || `${data.msg}:  ${data.url}`)
				.parent()
				.show();
		});
	}
};
