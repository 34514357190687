import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

function showCTTestimonials() {
	const ctTestimonialCC = 'c-ct-testimonials';

	Swiper.use([Autoplay, Navigation, Pagination]);

	if ($(`.${ctTestimonialCC}__slide-item`).length <= 1) {
		$(`.${ctTestimonialCC}__slider-wrapper`).addClass(
			`${ctTestimonialCC}__slider-wrapper--disabled`,
		);

		$(`.${ctTestimonialCC}__slider-btn-prev`).addClass(
			`${ctTestimonialCC}__slider-btn-prev--disabled`,
		);
		$(`.${ctTestimonialCC}__slider-btn-next`).addClass(
			`${ctTestimonialCC}__slider-btn-next--disabled`,
		);
	} else {
		$(`.${ctTestimonialCC}__slider-wrapper`).removeClass(
			`${ctTestimonialCC}__slider-wrapper--disabled`,
		);

		$(`.${ctTestimonialCC}__slider-btn-prev`).removeClass(
			`${ctTestimonialCC}__slider-btn-prev--disabled`,
		);
		$(`.${ctTestimonialCC}__slider-btn-next`).removeClass(
			`${ctTestimonialCC}__slider-btn-next--disabled`,
		);

		new Swiper(`.${ctTestimonialCC}__slider-container`, {
			loop: false,
			speed: 600,
			slidesPerView: 1,
			spaceBetween: 48,
			centeredSlides: true,
			centeredSlidesBounds: true,
			navigation: {
				prevEl: `.${ctTestimonialCC}__slider-btn-prev`,
				nextEl: `.${ctTestimonialCC}__slider-btn-next`,
			},
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				clickable: true
			},
			breakpoints: {
				992: {
					width: 608,
				},
			},
		});
	}
}

export default showCTTestimonials;
