function clickActionOutsideArea(event, componentClass) {
	if (
		$(event.target).is(`.${componentClass}__container`) === false &&
		$(`.${componentClass}__container`).has(event.target).length === 0
	) {
		if (
			$(`.${componentClass}__list-title-icon`).hasClass(
				`${componentClass}__list-title-icon--up`,
			)
		) {
			$(`.${componentClass}__list-title`).trigger('click');
		}
	}
}

function closeOpenedDropdownSubMenu(
	componentClass,
	navLink,
	hideSubNav = 'no',
) {
	navLink.removeClass(`${componentClass}__list-item-link--active`);

	if (hideSubNav == 'yes') {
		$(`.${componentClass}`).addClass(`${componentClass}--hidden`);
	}

	if (window.innerWidth < 992) {
		if (
			$(`.${componentClass}__list-title-text`)
				.text()
				.trim() !=
			$(`.${componentClass}__list-title-text`).data('inactiveTitle')
		) {
			$(`.${componentClass}__list-title-text`).text(
				$(`.${componentClass}__list-title-text`).data('inactiveTitle'),
			);
		}

		if (
			$(`.${componentClass}__list-title-icon`).hasClass(
				`${componentClass}__list-title-icon--up`,
			)
		) {
			$(`.${componentClass}__list-title`).trigger('click');
		}
	}
}

function scrollSecondaryNav(componentClass, navLink, activeSectionId, headerTopBarOuterHeight) {
	let sectionIdCollection = [];

	navLink.each(function() {
		let sectionId = $(this)
			.attr('href')
			.split('#')[1];

		sectionIdCollection.push(sectionId);

		if (
			$(window).scrollTop() +
			$('.l-header').outerHeight() -
			headerTopBarOuterHeight >=
			$(`.${componentClass}__list-title`).offset().top
		) {
			$(`.${componentClass}`).addClass(`${componentClass}--onScroll`);
		} else {
			if (
				$(`.${componentClass}`).hasClass(`${componentClass}--onScroll`)
			) {
				$(`.${componentClass}`).removeClass(
					`${componentClass}--onScroll`,
				);
			}
		}

		if (
			$(window).scrollTop() +
				$(`.${componentClass}__list-title`).outerHeight() +
				$('.l-header').outerHeight() -
				headerTopBarOuterHeight >=
				$(`#${sectionId}`).offset().top &&
			$(window).scrollTop() +
				$(`.${componentClass}__list-title`).outerHeight() +
				$('.l-header').outerHeight() -
				headerTopBarOuterHeight <
				$(`#${sectionId}`).offset().top +
					$(`#${sectionId}`).outerHeight()
		) {
			if ($(`.${componentClass}`).hasClass(`${componentClass}--hidden`)) {
				$(`.${componentClass}`).removeClass(
					`${componentClass}--hidden`,
				);
			}

			if (
				activeSectionId != sectionId ||
				$(`.${componentClass}__list-title-text`)
					.text()
					.trim() ==
					$(`.${componentClass}__list-title-text`).data(
						'inactiveTitle',
					)
			) {
				navLink.removeClass(
					`${componentClass}__list-item-link--active`,
				);

				$(`.${componentClass}__list`)
					.find('a[href="#' + sectionId + '"]')
					.addClass(`${componentClass}__list-item-link--active`);

				if (window.innerWidth < 992) {
					$(`.${componentClass}__list-title-text`).text(
						$(`.${componentClass}__list-item-link--active`)
							.text()
							.trim(),
					);
				}

				activeSectionId = sectionId;
			}
		}
	});

	let firstSectionWithId = sectionIdCollection[0];
	let lastSectionWithId = sectionIdCollection[sectionIdCollection.length - 1];

	// Subnav reached it's top position
	if (
		$(window).scrollTop() +
			$('.l-header').outerHeight() +
			$(`.${componentClass}__list-title`).outerHeight() <=
		$(`#${firstSectionWithId}`).offset().top
	) {
		closeOpenedDropdownSubMenu(componentClass, navLink);
	}

	// Subnav reached the bottom of the last appropriate element of it's items
	if (
		$(window).scrollTop() +
			$('.l-header').outerHeight() -
			headerTopBarOuterHeight +
			$(`.${componentClass}__list-title`).outerHeight() >=
		$(`#${lastSectionWithId}`).offset().top +
			$(`#${lastSectionWithId}`).outerHeight()
	) {
		closeOpenedDropdownSubMenu(componentClass, navLink, 'yes');
	}

	if (
		$(window).scrollTop() +
		$('.l-header').outerHeight() -
		headerTopBarOuterHeight >
			$(`.${componentClass}`).offset().top &&
		$(window).scrollTop() +
			$('.l-header').outerHeight() -
			headerTopBarOuterHeight +
			$(`.${componentClass}__list-title`).outerHeight() <=
			$(`#${lastSectionWithId}`).offset().top +
				$(`#${lastSectionWithId}`).outerHeight()
	) {
		$(`.${componentClass}`).addClass(`${componentClass}--scrollable`);
	} else {
		$(`.${componentClass}`).removeClass(`${componentClass}--scrollable`);
	}

	return activeSectionId;
}

function showCTSecondaryNavbar() {
	let componentClass = 'c-ct-secondary-navbar';
	let pricingTablesCC = 'c-ct-pricing-tables';
	let navLink = $(`.${componentClass}__list-item-link`);
	let navLocation = $(`.${componentClass}__inner`).data('navbarLocation');
	let activeSectionId = '';
	let headerTopBarOuterHeight = 0;

	$(document).on('click', function(e) {
		clickActionOutsideArea(e, componentClass);
	});

	$(`.${componentClass}__list-title`).on('click', function() {
		if (window.innerWidth < 992) {
			$(`.${componentClass}__list`).slideToggle(400);
			$(`.${componentClass}__list-title-icon`).toggleClass(
				`${componentClass}__list-title-icon--down ${componentClass}__list-title-icon--up`,
			);
		}
	});

	navLink.on('click', function(e) {
		e.preventDefault();

		if($('.l-header').hasClass('l-header--top-bar-included') && window.innerWidth >= 992) headerTopBarOuterHeight = $('.l-header__top-bar-wrapper').outerHeight();

		if (navLocation == 'pricing-page') {
			if (
				!$(this).hasClass(`${componentClass}__list-item-link--active`)
			) {
				$(`.${pricingTablesCC}`).fadeOut(0);

				let sectionId = $(this)
					.attr('href')
					.trim();

				$(`${sectionId}`).fadeIn();

				if (window.innerWidth < 992) {
					$(`.${componentClass}__list-title-text`).text(
						$(this)
							.text()
							.trim(),
					);
				}
			}
		}

		navLink.removeClass(`${componentClass}__list-item-link--active`);

		if (navLocation == 'faq-page') {
			$('html, body').animate(
				{
					scrollTop:
						$($(this).attr('href')).offset().top +
						2 +
						parseInt(
							$($(this).attr('href'))
								.css('padding-top')
								.slice(0, -2),
						) -
						30 -
						$(`.${componentClass}__list-title`).outerHeight() -
						$('.l-header').outerHeight() +
						headerTopBarOuterHeight,
				},
				'slow',
			);
		}

		if (navLocation == 'pricing-page') {
			$('html, body').animate(
				{
					scrollTop:
						$('#content-start').offset().top +
						$('#content-start').outerHeight() +
						parseInt(
							$($(this).attr('href'))
								.css('padding-top')
								.slice(0, -2),
						) -
						30 -
						$('.l-header').outerHeight() +
						headerTopBarOuterHeight,
				},
				'slow',
			);
		}

		$(this).addClass(`${componentClass}__list-item-link--active`);

		if (window.innerWidth < 992) {
			$(`.${componentClass}__list`).slideUp(400);

			if (
				$(`.${componentClass}__list-title-icon`).hasClass(
					`${componentClass}__list-title-icon--up`,
				)
			) {
				$(`.${componentClass}__list-title-icon`).toggleClass(
					`${componentClass}__list-title-icon--down ${componentClass}__list-title-icon--up`,
				);
			}
		}
	});

	$(window).on('scroll', function() {
		if($('.l-header').hasClass('l-header--top-bar-included') && window.innerWidth >= 992) headerTopBarOuterHeight = $('.l-header__top-bar-wrapper').outerHeight();

		if (navLocation == 'faq-page') {
			activeSectionId = scrollSecondaryNav(
				componentClass,
				navLink,
				activeSectionId,
				headerTopBarOuterHeight
			);
		}

		if (navLocation == 'pricing-page') {
			let activeSectionId = $(
				`.${componentClass}__list-item-link--active`,
			).attr('href');

			if (
				$(window).scrollTop() +
				$('.l-header').outerHeight() -
				headerTopBarOuterHeight >=
				$(`.${componentClass}__list-title`).offset().top
			) {
				$(`.${componentClass}`).addClass(`${componentClass}--onScroll`);
			} else {
				if (
					$(`.${componentClass}`).hasClass(
						`${componentClass}--onScroll`,
					)
				) {
					$(`.${componentClass}`).removeClass(
						`${componentClass}--onScroll`,
					);
				}
			}

			if (
				$(window).scrollTop() +
					$('.l-header').outerHeight() -
					headerTopBarOuterHeight +
					$(`.${componentClass}__list-title`).outerHeight() >=
				$(`${activeSectionId}`).offset().top +
					$(`${activeSectionId}`).outerHeight()
			) {
				$(`.${componentClass}`).addClass(`${componentClass}--hidden`);

				if (window.innerWidth < 992) {
					if (
						$(`.${componentClass}__list-title-icon`).hasClass(
							`${componentClass}__list-title-icon--up`,
						)
					) {
						$(`.${componentClass}__list-title`).trigger('click');
					}
				}
			} else {
				if (
					$(`.${componentClass}`).hasClass(
						`${componentClass}--hidden`,
					)
				) {
					$(`.${componentClass}`).removeClass(
						`${componentClass}--hidden`,
					);
				}
			}
		}
	});

	$(window).resize(function() {
		if (window.innerWidth >= 992) {
			$(`.${componentClass}__list`).css('display', 'flex');
			$(`.${componentClass}__list-title-text`).text(
				$(`.${componentClass}__list-title-text`).data('inactiveTitle'),
			);
		} else if (
			window.innerWidth < 992 &&
			$(`.${componentClass}__list`).css('display') === 'flex'
		) {
			if (
				!$(`.${componentClass}__list-title-icon`).hasClass(
					`${componentClass}__list-title-icon--up`,
				)
			) {
				$(`.${componentClass}__list`).css('display', 'none');
			}
		}
	});
}

export default showCTSecondaryNavbar;
