import '../../../js/vendors/fancybox/jquery.fancybox';

function showCTVideoText () {
	$( '[data-fancybox-trigger^="fancybox-video-text"]' ).fancybox( {
		baseClass: "fancybox-video-text",
		hash: false,
		arrows: false,
		hideScrollbar: true
	} );
}

export default showCTVideoText;
