function showCTTeamMember() {
	let componentClass = 'o-ct-team-member-card'

	$(`.${componentClass}__member-additional-info-link`).on('click', function(e) {
		e.preventDefault();

		let cardLink = $(this).attr('href');

		$(`${cardLink}`)
			.removeClass(`${componentClass}__member-info-modal--unfoldOut`)
			.addClass(`${componentClass}__member-info-modal--unfoldIn`);

		$('.js-body').addClass('h-scroll-lock');
	});

	$(`.${componentClass}__member-info-modal-close-btn`).on('click', function() {
		$(this)
			.parent()
			.parent()
			.removeClass(`${componentClass}__member-info-modal--unfoldIn`)
			.addClass(`${componentClass}__member-info-modal--unfoldOut`);

		$('.js-body').removeClass('h-scroll-lock');
	});
}

export default showCTTeamMember;
