import '../../../js/vendors/fancybox/jquery.fancybox';

function showCTImageTextVisualization () {
	$( '[data-fancybox-trigger^="fancybox-single-platform-video"]' ).fancybox( {
		baseClass: "fancybox-single-platform-video",
		hash: false,
		arrows: false,
		hideScrollbar: true
	} );
}

export default showCTImageTextVisualization;
