import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

function showCTInfoCardsSlider() {
	const ctInfoCardsSliderCC = 'c-ct-info-cards-slider';

	Swiper.use([Autoplay, Navigation, Pagination]);

	new Swiper(`.${ctInfoCardsSliderCC}__inner-container`, {
		loop: false,
		speed: 600,
		slidesPerView: 1,
		spaceBetween: 20,
		navigation: {
			clickable: true,
			prevEl: `.${ctInfoCardsSliderCC}__slider-btn-prev`,
			nextEl: `.${ctInfoCardsSliderCC}__slider-btn-next`
		},
		breakpoints: {
			768: {
				slidesPerView: 2
			},
			1200: {
				slidesPerView: 4,
				spaceBetween: 20
			},
			1400: {
				slidesPerView: 4,
				spaceBetween: 40,
			}
		},
	});
}

export default showCTInfoCardsSlider;
