import './util/modernizr';
import './util/polyfills';
import '../style.scss';
import './core/core';
/** Responsive Images Polyfill */
import 'picturefill';
import 'magnific-popup';

import jQuery from 'jquery';
// import Rellax from 'rellax';
////DO NOT ERASE OR PLOP IMPORTING WILL FAIL
////IMPORT FROM PLOP
// import * as Vivus from 'vivus';

import showCTImageTextVisualization from '../components/blocks-client/ct-image-text-visualization/ct-image-text-visualization';
import showCTInfoCardsSlider from '../components/blocks-client/ct-info-cards-slider/ct-info-cards-slider';
import showCTLatestPosts from '../components/blocks-client/ct-latest-posts/ct-latest-posts';
import showCTPhotoCollage from '../components/blocks-client/ct-photo-collage/ct-photo-collage';
import showCTSecondaryNavbar from '../components/blocks-client/ct-secondary-navbar/ct-secondary-navbar';
import showCTTestimonials from '../components/blocks-client/ct-testimonials/ct-testimonials';
import showCTVideoText from '../components/blocks-client/ct-video-text/ct-video-text';
// import showSecondaryNavbar from '../components/blocks-default/default-secondary-navbar/default-secondary-navbar';
import showCTHero from '../components/blocks-common/hero/hero-holder/hero-holder';
import introBlock from '../components/blocks-common/intro-block/intro-block';
import cardsHolderViewMore from '../components/blocks-default/default-image-cards/default-image-cards';
import showImagesSlider from '../components/blocks-default/default-slider-images/default-slider-images';
// import showInfoVideo from '../components/blocks-common/info-video/info-video';
import showDefaultTeamMembers from '../components/blocks-default/default-team/default-team';
import showTestimonials from '../components/blocks-default/default-testimonials/default-testimonials';
import showDefaultTextIcons from '../components/blocks-default/default-text-icons/default-text-icons';
import showJSTextIcons from '../components/blocks-default/default-text-icons/default-text-icons';
import showCTTeamMember from '../components/objects/cards/ct-team-member-card/ct-team-member-card';
import showAccordion from '../components/objects-default/accordion/accordion';
import ctaFull from '../components/objects-default/cta/cta-full/cta-full';
import mapBlock from '../components/objects-default/map/map';
import { hamburger } from '../components/objects-default/nav/hamburger/hamburger';
import navigateCategory from '../components/objects-default/nav/nav-category/nav-category';
import navMob from '../components/objects-default/nav/nav-mobile/nav-mobile';
import showRelatedArticles from '../components/objects-default/post/related-articles/related-articles';
import scrollToTop from '../components/objects-default/scroll-to-top/scroll-to-top';
import searchBar from '../components/objects-default/search/search-bar/search-bar';
import { navToggler } from '../components/objects-default/slide-out/slide-out';
import socialShare from '../components/objects-default/social/social-share/social-share';
import headRoom from '../layouts/header/header';
import parallaxScroll from './custom/parallax/parallaxscroll';
import scrollToAnchor from './custom/scroll/scroll-to-anchor';
import showOverviewBlog from './pages/overview-blog';
import showSingleBlogSidenav from './pages/single-blog-sidenav';

// functions below are only run if the component exists (verified through a "data-module" in the html)
// in the ajax container or page. Barba.js instantiates the functions below on container reload as well.

// Place in alphabetical order
const app = {
	cardsHolderViewMore,
	ctaFull,
	showAccordion,
	headRoom,
	navigateCategory,
	searchBar,
	scrollToTop,
	socialShare,
	hamburger,
	navToggler,
	mapBlock,
	// showInfoVideo,
	introBlock,
	showOverviewBlog,
	showSingleBlogSidenav,
	showDefaultTeamMembers,
	showDefaultTextIcons,
	// showSecondaryNavbar,
	showTestimonials,
	showCTHero,
	showCTLatestPosts,
	showCTSecondaryNavbar,
	showCTTeamMember,
	showCTTestimonials,
	showCTImageTextVisualization,
	showCTInfoCardsSlider,
	showCTPhotoCollage,
	showCTVideoText,
	showImagesSlider,
	showRelatedArticles,
	showJSTextIcons,
};

let calledModulesNames = [];
app.instantiate = function(elem) {
	const $this = $(elem);
	const module = $this.attr('data-module');

	if (module === undefined) {
		throw 'Module not defined (use data-module="")';
	} else if (module in app) {
		if ($this.attr('data-initialized') === 'true') {
			return;
		}
		new app[module](elem);
		$this.attr('data-initialized', true);
	} else {
		throw "Module '" + module + "' not found";
	}
};

jQuery(document).ready(function() {
	// // See the content of the Vivus object
	// console.log('Vivus object:', Vivus);
	//
	// setTimeout(function() {
	// 	// Wait for the page load of your page to start your animation
	// 	const viv = new Vivus('intro-2', {
	// 		type: 'delayed',
	// 		duration: 200,
	// 		animTimingFunction: Vivus.EASE,
	// 	});
	// 	console.log('Animation started', viv);
	// }, 1000);

	scrollToAnchor($);
	navMob($);

	// Component functions that should only run if the component exists in the ajax container or page.
	$('[data-module]').each(function() {
		if ($.inArray($(this).data('module'), calledModulesNames) == -1) {
			app.instantiate(this);

			calledModulesNames.push($(this).data('module'));
		}
	});

	// // Parallax. Move to different folder
	// $(window).on('load', function() {
	// 	let rellax = new Rellax('.rellax', {
	// 		center: true,
	// 	});
	// });
});

jQuery(window).scroll(function() {
	parallaxScroll(jQuery);

	// let scroll = $(window).scrollTop();
	//
	// if (scroll >= 10) {
	// 	$('.l-banner').addClass('l-banner--not-top');
	// 	$('.l-header').addClass('l-headroom--not-top');
	// } else {
	// 	$('.l-banner').removeClass('l-banner--not-top');
	// 	$('.l-header').removeClass('l-headroom--not-top');
	// }
});
