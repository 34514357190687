function showCTPhotoCollage() {
	let parent = $('.c-ct-photo-collage__media-wrapper');
	let cards = parent.children('.o-ct-team-member-card__wrapper');

	while (cards.length) {
		parent.append(cards.splice(Math.floor(Math.random() * cards.length), 1)[0]);
	}

	$('.c-ct-photo-collage__media-wrapper .o-ct-team-member-card__item').each(function (index) {
		$(this).attr('data-wow-delay', `${(index + 1)/10}s`);
	});
}

export default showCTPhotoCollage;
